import { BillOfLadingThirdPartyBillingAddress } from '@mothership/document-types'
import {
  MOTHERSHIP_PO_BOX_ADDRESS_CITY_STATE_ZIP,
  MOTHERSHIP_PO_BOX_ADDRESS_NAME,
  MOTHERSHIP_PO_BOX_ADDRESS_STREET,
} from '../../utils/billing'
import { Column } from '../Pane'
import { Text } from '../Text'
import {
  BOL_DEFAULT_BORDER,
  BOL_DEFAULT_HEADER_PADDING,
  BOL_DEFAULT_MARGIN,
  BOL_DEFAULT_PADDING,
} from './BillOfLadingDefaults'

interface BillOfLadingBillingProps {
  thirdPartyBillingAddress?: BillOfLadingThirdPartyBillingAddress
}

export const BillOfLadingBilling = (props: BillOfLadingBillingProps): JSX.Element => {
  const { thirdPartyBillingAddress } = props
  const addressName = thirdPartyBillingAddress?.name ?? MOTHERSHIP_PO_BOX_ADDRESS_NAME
  const street = thirdPartyBillingAddress?.street ?? MOTHERSHIP_PO_BOX_ADDRESS_STREET
  const cityStateZip =
    thirdPartyBillingAddress?.city && thirdPartyBillingAddress?.state && thirdPartyBillingAddress?.zip
      ? `${thirdPartyBillingAddress.city}, ${thirdPartyBillingAddress.state} ${thirdPartyBillingAddress.zip}`
      : MOTHERSHIP_PO_BOX_ADDRESS_CITY_STATE_ZIP

  return (
    <Column width={'100%'} border={BOL_DEFAULT_BORDER} marginTop={BOL_DEFAULT_MARGIN}>
      <Text
        backgroundColor="#eeeeee"
        borderBottom={BOL_DEFAULT_BORDER}
        width={'100%'}
        bold
        {...BOL_DEFAULT_HEADER_PADDING}
      >
        All freight charges are PRE-PAID - 3rd party bill to
      </Text>
      <Column padding={BOL_DEFAULT_PADDING} width={'100%'}>
        <Text>{addressName}</Text>
        <Text>{street}</Text>
        <Text>{cityStateZip}</Text>
      </Column>
    </Column>
  )
}
